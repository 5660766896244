import { messages as baseMessages } from "../../messages";

export const messages = {
  matchers: ["go", "go to"],
  successful: baseMessages.successWithoutReason,
  failed: "Sorry, didn't understand that",
  listing: [
    "Go forward/back {number} character(s)",
    "Go forward/back {number} word(s)",
    "Go forward/back {number} paragraph(s)",
    "Go to the start/end of line",
    "Go to the start/end of document",
  ],
};
