import type { CommandConfig } from "../../../types";

import { getPlatform } from "@carescribe/utilities/src/browser";
import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { clear as performClear } from "../../../sagas/commands/clear/clear";
import { pressKeys } from "../../../sagas/commands/insertion/pressKeys/pressKeys";
import { createCommandDefinition } from "../utils/createCommandDefinition";

export const clear: CommandConfig = createCommandDefinition({
  id: "clear",
  matchers: messages.matchers,
  deadline: toSeconds(1),
  messages,
  performCommand: {
    talktype: performClear,
    app: pressKeys([
      {
        key: "a",
        ctrlKey: getPlatform() === "win",
        metaKey: getPlatform() === "mac",
      },
      { key: "Backspace" },
    ]),
  },
  input: messages.listing,
  track: { name: "Text Cleared", data: {} },
});
