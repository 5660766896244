import { joinStringsForRegExp } from "./joinStringsForRegExp";

/**
 * Matches the longest string from the targets array within the given text.
 *
 * @param text - The text to search within.
 * @param targets - The array of target strings to match.
 * @returns The longest matching string or `null` if not found.
 *
 * @example
 * matchLongestString('This is the longest match',
 * ['long', 'longest', 'match']);
 * // Returns 'longest'
 *
 * @example
 * matchLongestString('This is a match', ['match', 'a']);
 * // Returns 'match'
 *
 * @example
 * matchLongestString('No match here', ['longest', 'match']);
 * // Returns null
 */
export const matchLongestString = <Targets extends string[]>(
  text: string,
  targets: Targets
): Targets[number] | null => {
  if (targets.length === 0) {
    return null;
  }
  /**
   * Ordering is important to ensure the longest targets are placed first.
   * Otherwise, we may end up matching a shorter length match.
   */
  const orderedTargets = [...targets].sort((a, b) => b.length - a.length);
  const regex = new RegExp(joinStringsForRegExp(orderedTargets), "g");
  const match = text.match(regex);
  return match?.reduce((a, b) => (a.length > b.length ? a : b)) ?? null;
};
