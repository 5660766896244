import type { MoveSelectionInstruction } from "@carescribe/types";
import type { BaseEditor } from "slate";
import type { HistoryEditor } from "slate-history";
import type { ReactEditor } from "slate-react";

import { Transforms } from "slate";

/**
 * Moves the selection cursor forward or back by a specified number of words.
 *
 * @param editor - The editor instance
 * @param instruction - The instruction for movement
 *   - direction: "forward" or "back"
 *   - distance: number of words to move
 *
 * @example
 *
 * Instruction: { unit: "word", direction: "forward", distance: 2 }
 * Description: Moves the cursor 2 words forward
 *
 * X marks the cursor position.
 *
 * Before:
 * XLorem ipsum dolor sit amet, consectetur adipiscing elit.
 *
 * After:
 * Lorem ipsumX dolor sit amet, consectetur adipiscing elit.
 */
export const handleWord = ({
  editor,
  instruction: { direction, distance },
}: {
  editor: BaseEditor & ReactEditor & HistoryEditor;
  instruction: MoveSelectionInstruction & { unit: "word" };
}): void => {
  Transforms.collapse(editor, { edge: "anchor" });

  const reverse = direction === "back";
  Transforms.move(editor, { reverse, distance, unit: "word" });
};
