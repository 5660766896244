import type { CommandConfig } from "../../../../types";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { pressKeys } from "../../../../sagas/commands/insertion/pressKeys/pressKeys";
import { deleteSelection as performDeleteSelection } from "../../../../sagas/commands/selection/deleteSelection";
import { createCommandDefinition } from "../../utils/createCommandDefinition";

export const deleteSelection: CommandConfig = createCommandDefinition({
  id: "deleteSelection",
  matchers: messages.matchers,
  deadline: toSeconds(0),
  messages,
  performCommand: {
    talktype: performDeleteSelection,
    app: pressKeys([
      // Lead with a space to ensure that if the selection is lost as the user
      // issues the command, the space will be deleted instead of the text
      { key: "Space" },
      { key: "Backspace" },
    ]),
  },
  input: messages.listing,
  track: { name: "Text Deleted", data: { deletionType: "selection" } },
});
