import type { MoveSelectionInstruction } from "@carescribe/types";
import type { BaseEditor } from "slate";
import type { HistoryEditor } from "slate-history";
import type { ReactEditor } from "slate-react";

import { match } from "ts-pattern";

import { handleCharacter } from "./handleCharacter";
import { handleDocument } from "./handleDocument";
import { handleLine } from "./handleLine";
import { handleParagraph } from "./handleParagraph";
import { handleWord } from "./handleWord";

/**
 * Move the selection within the editor based on the given instruction.
 *
 * @param editor - The editor instance which includes.
 * @param instruction - The instruction detailing how to move the selection.
 *
 * @example
 * // Move the selection one character forward
 * moveSelection(editor, { unit: "character", direction: "forward",
 * distance: 1});
 *
 * // Move the selection two words backward
 * moveSelection(editor, { unit: "word", direction: "back", distance: 2 });
 *
 * // Move the selection to the next paragraph
 * moveSelection(editor,
 * { unit: "paragraph", direction: "forward", distance: 1 });
 *
 * // Move the selection to the start of the current line
 * moveSelection(editor, { unit: "line", direction: "start", distance: null });
 *
 * // Move the selection to the end of the document
 * moveSelection(editor,
 * { unit: "document", direction: "end", distance: null });
 */
export const moveSelection = (
  editor: BaseEditor & ReactEditor & HistoryEditor,
  instruction: MoveSelectionInstruction
): void =>
  match({ instruction, editor })
    .with({ instruction: { unit: "character" } }, handleCharacter)
    .with({ instruction: { unit: "word" } }, handleWord)
    .with({ instruction: { unit: "line" } }, handleLine)
    .with({ instruction: { unit: "paragraph" } }, handleParagraph)
    .with({ instruction: { unit: "document" } }, handleDocument)
    .exhaustive();
