import type { PromptWithCommand } from "../types";
import type { AppMetadata } from "@talktype/types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put } from "redux-saga/effects";

import { voiceCommandUnsupported, voiceCommandComplete } from "./actions";

/**
 * Given a command, performs its configured action for the appropriate
 * target type.
 *
 * If the command action is not supported for the target type, the command is
 * considered unrecognised.
 */
export const performCommand = function* ({
  resultId,
  prompt,
  targetApp,
}: {
  resultId: string;
  prompt: PromptWithCommand;
  targetApp: AppMetadata;
}): SagaIterator<void> {
  if (!("performCommand" in prompt.command)) {
    return;
  }

  const performCommand =
    prompt.command.performCommand[targetApp.isSelf ? "talktype" : "app"];

  if (!performCommand) {
    yield put(voiceCommandUnsupported({ targetApp }));
    return;
  }

  const commandSuccessful: SagaReturnType<typeof performCommand> = yield call(
    performCommand,
    prompt.parameters
  );

  const status = commandSuccessful ? "successful" : "failed";

  yield put(
    voiceCommandComplete({
      id: { resultId, commandIndex: 0 },
      prompt,
      outcome: { status, detail: prompt.command.messages[status] },
    })
  );
};
