import type { CommandConfig } from "../../../../types";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { deleteRange as performDeleteRange } from "../../../../sagas/commands/deletion";
import { createCommandDefinition } from "../../utils/createCommandDefinition";

export const deleteRange: CommandConfig = createCommandDefinition({
  id: "deleteRange",
  matchers: messages.matchers,
  deadline: toSeconds(5),
  messages,
  performCommand: {
    talktype: performDeleteRange({ to: messages.to, from: messages.from }),
    app: null,
  },
  input: messages.listing,
  track: {
    name: "Text Deleted",
    data: { deletionType: "range" },
    keywords: messages.to,
  },
});
