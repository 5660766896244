import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { all, call, put, take, takeEvery } from "redux-saga/effects";

import { mediaStreamError } from "@carescribe/audio/src/sagas/actions";
import {
  requestCloseCurrentWebSocket,
  requestNewTranscriberSocket,
} from "@carescribe/transcriber-connection/src";
import { assertUnreachable } from "@carescribe/utilities/src/types";

import { requestStopDictating } from "@talktype/actions";
import { startDictating, stopDictating } from "@talktype/editor";
import { addToast, dismissToast } from "@talktype/toasts/src/sagas/actions";
import {
  requestStartAutoReconnect,
  requestStartMonitoringConnection,
  requestStopAutoReconnect,
  requestStopMonitoringConnection,
} from "@talktype/transcriber/src/sagas/actions";
import { constructTranscriberUrl } from "@talktype/transcriber/src/sagas/utils/constructTranscriberUrl";
import {
  gotDictationSession,
  requestDictationSession,
  requestLogout,
} from "@talktype/user/src/sagas/actions";

const CONNECTION_ERROR_TOAST_ID = "dictation_session_error";

/**
 * Connect on Dictation Start
 *
 * - Requests a new connection to the transcriber when the user starts dictating
 * - Requests the socket be closed when the user stops dictating
 */
export const connectOnDictationStart = function* (): SagaIterator<void> {
  yield takeEvery(startDictating, function* () {
    yield put(requestDictationSession());
    const {
      payload: dictationSession,
    }: SagaReturnType<typeof gotDictationSession> = yield take(
      gotDictationSession
    );

    if (dictationSession.error) {
      yield put(requestStopDictating());

      switch (dictationSession.error) {
        case "connection-error":
          yield put(
            addToast({
              id: CONNECTION_ERROR_TOAST_ID,
              type: "failed_connection",
              dismissConfig: { after: null, notBefore: null },
              order: 0,
            })
          );
          return;
        case "permission-error":
        case "unknown-error":
          yield put(requestLogout());
          return;
        default:
          yield call(assertUnreachable, dictationSession);
          return;
      }
    }

    yield put(dismissToast(CONNECTION_ERROR_TOAST_ID));

    const url: SagaReturnType<typeof constructTranscriberUrl> = yield call(
      constructTranscriberUrl,
      dictationSession.url
    );

    yield all([
      put(requestStartAutoReconnect({ url })),
      put(requestNewTranscriberSocket({ url })),
      put(requestStartMonitoringConnection()),
    ]);
  });

  yield takeEvery(mediaStreamError, function* () {
    yield put(requestStopDictating());
  });

  yield takeEvery(stopDictating, function* () {
    yield all([
      put(requestStopAutoReconnect()),
      put(requestCloseCurrentWebSocket()),
      put(requestStopMonitoringConnection()),
    ]);
  });
};
