import type { MoveSelectionInstruction } from "@carescribe/types";
import type { BaseEditor } from "slate";
import type { HistoryEditor } from "slate-history";
import type { ReactEditor } from "slate-react";

import { Transforms } from "slate";

/**
 * Moves the selection cursor forward or back by a specified number of
 * characters.
 *
 * @param editor - The editor instance
 * @param instruction - The instruction for movement
 *   - direction: "forward" or "back"
 *   - distance: number of characters to move
 *
 * @example
 *
 * Instruction: { unit: "character", direction: "forward", distance: 5 }
 * Description: Moves the cursor 5 characters forward
 *
 * X marks the cursor position.
 *
 * Before:
 * XLorem ipsum dolor sit amet, consectetur adipiscing elit.
 *
 * After:
 * LoremX ipsum dolor sit amet, consectetur adipiscing elit.
 */
export const handleCharacter = ({
  editor,
  instruction: { direction, distance },
}: {
  editor: BaseEditor & ReactEditor & HistoryEditor;
  instruction: MoveSelectionInstruction & { unit: "character" };
}): void => {
  Transforms.collapse(editor, { edge: "anchor" });

  const reverse = direction === "back";
  Transforms.move(editor, { reverse, distance, unit: "offset" });
};
