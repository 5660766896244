import type { AppDispatch, RootState } from "@talktype/store";
import type { DictationButtonProps } from "@talktype/ui/src/DictationButton";
import type { MouseEvent, KeyboardEvent } from "react";

import { connect } from "react-redux";

import { requestToggleDictation } from "@talktype/actions";
import { selectDictationStatus } from "@talktype/editor/src/reducers/editor";
import { DictationButton as Component } from "@talktype/ui/src/DictationButton";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

const mapStateToProps = (
  state: RootState
): Pick<DictationButtonProps, "status"> => ({
  status: selectDictationStatus(state),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): Pick<DictationButtonProps, "onClick"> => ({
  onClick: (event: MouseEvent | KeyboardEvent) =>
    dispatch(
      requestToggleDictation({
        event,
        interactionMethod: getInteractionMethodFromEvent(event),
      })
    ),
});

export const DictationButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
