import type { Command } from "../../../types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, take } from "redux-saga/effects";

import { InteractionMethod, requestTrackEvent } from "@talktype/analytics";
import {
  requestMoveSelection as requestMoveSelectionApp,
  selectionMoved as selectionMovedApp,
} from "@talktype/dictate-to-app/src/sagas/actions";
import {
  requestMoveSelection as requestMoveSelectionEditor,
  selectionMoved as selectionMovedEditor,
} from "@talktype/editor/src/sagas/actions";

import { parseNavigationParameters } from "./parseNavigationParameters";

const actions = {
  app: {
    requestMoveSelection: requestMoveSelectionApp,
    selectionMoved: selectionMovedApp,
  },
  talktype: {
    requestMoveSelection: requestMoveSelectionEditor,
    selectionMoved: selectionMovedEditor,
  },
};

export const performNavigation = (type: "app" | "talktype"): Command => {
  const { requestMoveSelection, selectionMoved } = actions[type];

  return function* (parameters: string): SagaIterator<boolean> {
    const instruction: SagaReturnType<typeof parseNavigationParameters> =
      yield call(parseNavigationParameters, parameters);

    if (instruction === null) {
      return false;
    }

    yield put(requestMoveSelection(instruction));
    const {
      payload: { success },
    }: SagaReturnType<typeof selectionMoved> = yield take(selectionMoved);

    yield put(
      requestTrackEvent({
        name: "Text Navigated",
        data: {
          navigatedTextInstruction: instruction,
          interactionMethod: InteractionMethod.voice,
          commandStatus: success ? "successful" : "failed",
          instruction: parameters,
        },
      })
    );

    return success;
  };
};
