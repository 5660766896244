import type { DeviceInfo } from "../types";
import type { SagaIterator, Task } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import {
  take,
  cancel,
  fork,
  put,
  call,
  all,
  takeEvery,
} from "redux-saga/effects";

import { isObjectWithMessage } from "@carescribe/utilities/src/guards/isObjectWithMessage";

import {
  audioConnected,
  createdAudioStream,
  createdMediaRecorder,
  requestDisconnectAudio,
  requestInputDevices,
  notAllowedUserMedia,
  notFoundUserMedia,
  mediaStreamError,
} from "./actions";
import { SEND_MEDIA_FREQUENCY } from "./setUpSendAudioOverSocket";
import {
  createMediaRecorder,
  getUserMedia,
  stopStream,
  log,
  getUserDevices,
  filterDevices,
  logError,
} from "./utils";
import { setInputDevices } from "../reducers/audio";

type Recorder = SagaReturnType<typeof createMediaRecorder>;
type MaybeStream = MediaStream | null;
type MaybeRecorder = Recorder | null;

type Listener = (blobEvent: BlobEvent) => void;
type SetStream = (stream: MediaStream | null) => void;
type SetRecorder = (recorder: Recorder | null) => void;

type SetUpConfig = {
  listener: Listener;
  setStream: SetStream;
  setRecorder: SetRecorder;
  deviceId?: string | null;
};

const setUp = function* ({
  listener,
  setStream,
  setRecorder,
  deviceId = null,
}: SetUpConfig): SagaIterator<void> {
  yield call(log, "connecting to audio");

  try {
    const { stream, error }: SagaReturnType<typeof getUserMedia> = yield call(
      getUserMedia,
      deviceId
    );

    if (error) {
      throw error;
    }

    yield put(createdAudioStream(stream));

    yield call(log, "stream started");

    const recorder: Recorder = yield call(createMediaRecorder, {
      stream,
      listener,
      frequency: SEND_MEDIA_FREQUENCY,
    });

    yield put(createdMediaRecorder(recorder));

    yield call(log, "recorder started");

    yield call(setStream, stream);
    yield call(setRecorder, recorder);

    yield put(audioConnected());
  } catch (error) {
    yield call(
      logError,
      "unable to create stream:",
      isObjectWithMessage(error) ? error.message : error
    );

    yield put(mediaStreamError());
  }
};

type TearDownConfig = {
  currentRecorder: MaybeRecorder;
  currentStream: MaybeStream;
  setStream: SetStream;
  setRecorder: SetRecorder;
};

const tearDown = function* ({
  currentRecorder,
  currentStream,
  setRecorder,
  setStream,
}: TearDownConfig): SagaIterator<void> {
  yield call(log, "disconnecting from audio");

  if (currentRecorder) {
    yield call([currentRecorder, "stop"]);
    yield call(log, "recorder stopped");
    yield call(setRecorder, null);
  }

  if (currentStream) {
    yield call(stopStream, currentStream);
    yield call(log, "stream stopped");
    yield call(setStream, null);
  }
};

export const audio = function* (
  listener: Listener,
  deviceId: string | null
): SagaIterator<void> {
  let currentStream: MaybeStream = null;
  let currentRecorder: MaybeRecorder = null;

  const setStream: SetStream = (stream) => {
    currentStream = stream;
  };
  const setRecorder: SetRecorder = (recorder) => {
    currentRecorder = recorder;
  };

  const setupTask: Task = yield fork(setUp, {
    listener,
    setStream,
    setRecorder,
    deviceId,
  });

  try {
    yield take(requestDisconnectAudio);
  } finally {
    yield cancel(setupTask);

    yield fork(tearDown, {
      currentRecorder,
      currentStream,
      setRecorder,
      setStream,
    });
  }
};

const getInputDevices = function* (): SagaIterator<void> {
  // requests microphone permission
  const { error }: SagaReturnType<typeof getUserMedia> = yield call(
    getUserMedia,
    null
  );

  if (error) {
    switch (error.name) {
      case "NotAllowedError":
        // microphone permission is turned off at a system level
        yield put(notAllowedUserMedia());
        return;

      case "NotFoundError":
        // matching audio media is not available
        yield put(notFoundUserMedia());
        return;
    }
  }

  // get user's devices
  const mediaDevices: SagaReturnType<typeof getUserDevices> = yield call(
    getUserDevices
  );

  // filter out audio drivers
  const filteredDevices: DeviceInfo[] = yield call(filterDevices, mediaDevices);

  // set devices into state
  yield put(setInputDevices(filteredDevices));
};

export const audioSaga = function* (): SagaIterator<void> {
  yield all([takeEvery(requestInputDevices, getInputDevices)]);
};
