import type { MoveSelectionInstruction } from "@carescribe/types/src/MoveSelectionInstruction";
import type { BaseEditor } from "slate";
import type { HistoryEditor } from "slate-history";
import type { ReactEditor } from "slate-react";

import { Transforms } from "slate";

import { findFirstLeafTextNode } from "@carescribe/utilities/src/findFirstLeafTextNode";
import { getEdgeOfLineOffsets } from "@carescribe/utilities/src/getEdgeOfLineOffsets";
import { isUndefined } from "@carescribe/utilities/src/isUndefined";

/**
 * Moves the selection cursor to the start or end of the current line.
 *
 * @param editor - The editor instance
 * @param instruction - The instruction for movement
 *   - direction: "start" or "end"
 *
 * @example
 *
 * Instruction: { unit: "line", direction: "end", distance: null }
 * Description: Moves the cursor to the end of the current line
 *
 * X marks the cursor position.
 *
 * Before:
 * Lorem ipsum dolor sit amet,
 * consectetur Xadipiscing elit.
 * Curabitur vehicula.
 *
 * After:
 * Lorem ipsum dolor sit amet,
 * consectetur adipiscing elit.X
 * Curabitur vehicula.
 */
export const handleLine = ({
  editor,
  instruction: { direction },
}: {
  editor: BaseEditor & ReactEditor & HistoryEditor;
  instruction: MoveSelectionInstruction & { unit: "line" };
}): void => {
  const editorSelection = editor.selection;
  const DOMSelection = window.getSelection();

  if (!editorSelection || !DOMSelection) {
    return;
  }

  /*
   * We use the DOM selection here because Slate does not have an internal
   * concept of where node text wraps onto other lines. This allows us to
   * accurately determine the visual position of the cursor within the text.
   */
  const DOMSelectionRange = DOMSelection.getRangeAt(0);
  const DOMSelectionContainer = findFirstLeafTextNode(
    DOMSelectionRange.startContainer
  );

  if (!DOMSelectionContainer) {
    throw new Error("No text selection found");
  }

  const offsets = getEdgeOfLineOffsets(DOMSelectionContainer, direction);
  /**
   * The order of offsets is crucial to find the closest offset to the
   * current one in the specified direction.
   *
   * @example
   *
   * Consider searching for the nearest offset in the "start" direction,
   * with the current offset positioned towards the end of the selection
   * block.
   *
   * If we begin comparing from the start, we might select the first offset
   * that is lower than the current (`offset <= currentOffset`) instead of
   * the one that is both lower and closer.
   */
  const orderedOffsets = direction === "end" ? offsets : [...offsets].reverse();
  const editorSelectionFocus = editorSelection.focus;
  const currentOffset = editorSelectionFocus.offset;

  const closesOffset = orderedOffsets.find((offset) =>
    direction === "end" ? offset >= currentOffset : offset <= currentOffset
  );

  /**
   * Mathematically, there should always be a closest offset.
   * `find` however is capable of returning `undefined` and so type wise we must
   * guard against it.
   */
  if (isUndefined(closesOffset)) {
    return;
  }

  const newSelection = {
    path: editorSelectionFocus.path,
    offset: closesOffset,
  };

  Transforms.select(editor, newSelection);
};
