import type { InteractionMethod } from "@talktype/analytics";
import type {
  AssignedResult,
  InitialResult,
  InProgressResult,
  Result,
} from "@talktype/types";
import type { CrucialNetworkError } from "@talktype/types/src/CrucialNetworkError";
import type { MouseEvent, KeyboardEvent } from "react";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

const action = prefixActionCreator("saga/shared");

export const requestFinaliseDictation = action<
  void,
  "request-finalise-dictation"
>("request-finalise-dictation");

export const finalisedDictation = action<void, "finalised-dictation">(
  "finalised-dictation"
);

export const inProgressResultRef = action<
  Readonly<InProgressResult>,
  "in-progress-text-ref"
>("in-progress-text-ref");

export const requestProcessResult = action<
  InitialResult | AssignedResult,
  "request-process-result"
>("request-process-result");

export const processedResult = action<Result, "processed-result">(
  "processed-result"
);

export const requestStopDictating = action<void, "request-stop-dictating">(
  "request-stop-dictating"
);

export const requestToggleDictation = action<
  { event?: MouseEvent | KeyboardEvent; interactionMethod: InteractionMethod },
  "request-toggle-dictation"
>("request-toggle-dictation");

export const crucialNetworkRequestFailed = action<
  CrucialNetworkError,
  "crucial-network-request-failed"
>("crucial-network-request-failed");
