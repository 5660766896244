import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { select, takeEvery, put, take } from "redux-saga/effects";

import {
  requestProcessResult,
  processedResult,
  requestFinaliseDictation,
  finalisedDictation,
} from "@talktype/actions";
import {
  requestSaveEditorMarks,
  savedEditorMarks,
} from "@talktype/editor/src/sagas/actions";
import {
  resultSent,
  requestSendResult,
} from "@talktype/results/src/sagas/actions";
import { selectActiveApp } from "@talktype/system";

import {
  requestCategoriseResult,
  requestReshapeResult,
  requestAssembleTranscript,
  categorisedResult,
  reshapedResult,
  assembledTranscript,
  requestTrackResult,
  trackedResult,
  requestFinaliseInProgressResult,
  finalisedInProgressResult,
} from "./actions";

/**
 * Manages the processing of a result.
 *
 * The result is processed in a pipeline of effects:
 * - Categorise
 * - Save editor marks before adding new results
 * - Finalise in-progress result if subsequent new result is received
 * - Track in-progress result
 * - Reshape
 * - Assemble
 * - Add to editor
 */
export const resultsPipeline = function* (): SagaIterator<void> {
  yield takeEvery(requestProcessResult, function* ({ payload: initialResult }) {
    const activeApp: SagaReturnType<typeof selectActiveApp> = yield select(
      selectActiveApp
    );

    /**
     * Each result should be linked to a respective target app.
     * By default, this will be the active app at the time processing is
     * requested, unless a target app was already present.
     */
    const result = {
      ...initialResult,
      targetApp:
        "targetApp" in initialResult ? initialResult.targetApp : activeApp,
    };

    // Entry point -> Categorise
    yield put(requestCategoriseResult(result));

    const {
      payload: { isStale, isNew, previousIsUnfinalised },
    }: SagaReturnType<typeof categorisedResult> = yield take(categorisedResult);

    if (isStale) {
      yield put(processedResult({ ...result, transcript: [] }));
      return;
    }

    // Save editor marks for new results
    if (isNew) {
      yield put(requestSaveEditorMarks());
      yield take(savedEditorMarks);
    }

    // Finalise in-progress result if subsequent new result is received
    if (previousIsUnfinalised) {
      yield put(requestFinaliseInProgressResult());
      yield take(finalisedInProgressResult);
    }

    // Categorise -> Track in-progress result
    yield put(requestTrackResult(result));
    yield take(trackedResult);

    // Reshape
    yield put(requestReshapeResult(result));
    const {
      payload: resultAfterReshaping,
    }: SagaReturnType<typeof reshapedResult> = yield take(reshapedResult);

    // Assemble
    yield put(requestAssembleTranscript(resultAfterReshaping));
    const {
      payload: transformedResult,
    }: SagaReturnType<typeof assembledTranscript> = yield take(
      assembledTranscript
    );

    /**
     * When dictating to other apps:
     *
     * Finalise if non-text segments are present.
     *
     * When it comes to non-text segments, we cannot guarantee how the target
     * app has interpreted them. Therefore, removing stale content from such
     * in-progress results is not currently possible.
     *
     * Example:
     *
     * Line break segments are inserted by pressing the "Enter" + "Shift" keys.
     * Normally, most apps would interpret this as a new line. However, in a
     * small number of cases, the app may interpret this differently
     * (e.g form submission) meaning we can't simply delete characters to
     * remove the stale content.
     */
    const nonTextSegmentPresentInDictatingToApp =
      transformedResult.targetApp.isSelf === false &&
      transformedResult.isFinal === false &&
      transformedResult.transcript.some((segment) => segment.type !== "text");

    if (nonTextSegmentPresentInDictatingToApp) {
      yield put(requestFinaliseDictation());
      yield take(finalisedDictation);
      return;
    }

    yield put(requestSendResult(transformedResult));

    // Added -> Exit point
    yield take(resultSent);
    yield put(processedResult(transformedResult));
  });
};
