import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { finaliseWhenActiveAppChanges } from "./finaliseWhenActiveAppChanges";
import { manageCopyDictationHistoryEntry } from "./manageCopyDictationHistoryEntry";
import { manageDictateToAppPrecedingText } from "./manageDictateToAppPrecedingText";
import { manageDictationHistory } from "./manageDictationHistory";
import { manageMoveSelection } from "./manageMoveSelection";
import { manageNotifications } from "./manageNotifications";
import { manageSendResultToApp } from "./manageSendResultToApp";
import { resetDictateToApp } from "./resetDictateToApp";

export const setUpDictateAnywhere = function* (): SagaIterator<void> {
  yield all(
    [
      finaliseWhenActiveAppChanges,
      manageCopyDictationHistoryEntry,
      manageDictateToAppPrecedingText,
      manageDictationHistory,
      manageNotifications,
      manageSendResultToApp,
      manageMoveSelection,
      resetDictateToApp,
    ].map(retry)
  );
};
