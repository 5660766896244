import type { CommandConfig } from "../../../../types";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { performNavigation } from "../../../../sagas/commands/navigation/performNavigation";
import { createCommandDefinition } from "../../utils/createCommandDefinition";

export const goTo: CommandConfig = createCommandDefinition({
  id: "goTo",
  matchers: messages.matchers,
  deadline: toSeconds(5),
  messages,
  performCommand: {
    talktype: performNavigation("talktype"),
    app: performNavigation("app"),
  },
  input: messages.listing,
  track: null,
});
