/**
 * Finds the first leaf text node within a given node.
 *
 * @param node - The node to search within.
 * @returns The first leaf text node found, or null if none.
 */
export const findFirstLeafTextNode = (node: Node): Node | null => {
  if (node.nodeType === Node.TEXT_NODE) {
    return node;
  }

  for (const child of node.childNodes) {
    const textNode = findFirstLeafTextNode(child);
    if (textNode) {
      return textNode;
    }
  }

  return null;
};
