import type { Time } from "@carescribe/types/src/TranscriberResponses";
import type { Seconds } from "@carescribe/types/src/Units";

export const toSeconds = (magnitude: number): Seconds => ({
  quantity: "time",
  unit: "seconds",
  magnitude,
});

const nanosInSeconds = 1_000_000_000;
const millisecondsInSeconds = 1_000;
export const secondsInMinute = 60;
export const secondsInHour = secondsInMinute * 60;
export const secondsInDay = secondsInHour * 24;
export const secondsInWeek = secondsInDay * 7;

const fallbackTimeString = "--:--";

const padTime = (duration: number): string =>
  duration.toString().padStart(2, "0");

export const secondsToTimeString = (
  { magnitude }: Seconds,
  delimiter = ":"
): string => {
  if (magnitude === Infinity || isNaN(magnitude)) {
    return fallbackTimeString;
  }

  const hours = Math.floor(magnitude / secondsInHour);

  const remainingMinutes = magnitude % secondsInHour;
  const minutes = padTime(Math.floor(remainingMinutes / secondsInMinute));
  const seconds = padTime(Math.floor(remainingMinutes % secondsInMinute));

  return (hours > 0 ? [hours.toString()] : [])
    .concat([minutes, seconds])
    .join(delimiter);
};

/**
 * Converts seconds to a readable string in the format
 * "X hours Y minutes Z seconds"
 * Use with @visuallyHidden to read out the duration for screen readers
 */
export const secondsToDurationReadString = (
  { magnitude }: Seconds,
  delimiter = " "
): string => {
  if (magnitude === Infinity || isNaN(magnitude)) {
    return fallbackTimeString;
  }

  const workingHours = Math.floor(magnitude / secondsInHour);
  const hours =
    workingHours > 0
      ? workingHours === 1
        ? [workingHours.toString() + " hour"]
        : [workingHours.toString() + " hours"]
      : [];

  const remainingMinutes = magnitude % secondsInHour;
  const workingMinutes = Math.floor(remainingMinutes / secondsInMinute);
  const minutes =
    workingMinutes > 0
      ? workingMinutes === 1
        ? [workingMinutes.toString() + " minute"]
        : [workingMinutes.toString() + " minutes"]
      : [];
  const seconds =
    Math.floor(remainingMinutes % secondsInMinute).toString() + " seconds";

  return hours.concat(minutes, seconds).join(delimiter);
};

export const timeUIString = (time: Seconds | null): string =>
  time == null ? fallbackTimeString : secondsToTimeString(time);

export const secondsToMilliseconds = (seconds: Seconds): number =>
  seconds.magnitude * millisecondsInSeconds;

export const millisecondsToSeconds = (milliseconds: number): Seconds =>
  toSeconds(milliseconds / millisecondsInSeconds);

export const timeToSeconds = (time: Time): Seconds =>
  toSeconds(time.seconds + time.nanos / nanosInSeconds);

export const emptyTime: Time = {
  seconds: 0,
  nanos: 0,
};

export const rangeContainsTime = (
  range: [Seconds, Seconds],
  time?: Seconds
): boolean =>
  !!time &&
  time.magnitude >= range[0].magnitude &&
  time.magnitude < range[1].magnitude;

export const timeIsBeforeTime = (time1: Seconds, time2?: Seconds): boolean =>
  !!time2 && time1.magnitude < time2.magnitude;

export const addSeconds = (time1: Seconds, time2: Seconds): Seconds => ({
  ...time1,
  magnitude: time1.magnitude + time2.magnitude,
});

export const subtractSeconds = (time1: Seconds, time2: Seconds): Seconds => ({
  ...time1,
  magnitude: time1.magnitude - time2.magnitude,
});
