import type { MoveSelectionInstruction } from "@carescribe/types";
import type { Platform } from "@carescribe/types/src/Platform";
import type { KeyPress } from "@talktype/types/src/Ipc/KeyPress";

export const handleWord = async ({
  instruction: { direction, distance },
  platform,
  pressKeys,
}: {
  instruction: MoveSelectionInstruction & { unit: "word" };
  platform: Extract<Platform, "win" | "mac">;
  pressKeys: (keypresses: KeyPress[]) => Promise<boolean>;
}): Promise<boolean> => {
  const keypresses: KeyPress[] = [];
  const key = direction === "forward" ? "Right" : "Left";
  const keypressPerPlatform: Record<"mac" | "win", KeyPress> = {
    mac: { key, altKey: true },
    win: { key, ctrlKey: true },
  };
  const keypress: KeyPress = keypressPerPlatform[platform];

  for (let index = 0; index < distance; index++) {
    keypresses.push(keypress);
  }

  return pressKeys(keypresses);
};
