import type { BaseEditor } from "slate";
import type { HistoryEditor } from "slate-history";
import type { ReactEditor } from "slate-react";

import { Editor } from "slate";

/**
 * Given a marks object, sets a mark at the current selection.
 *
 * In an ideal world, this would be a one-liner:
 *
 * ```
 * Editor.addMark(editor, mark, value)
 * ```
 *
 * @example
 * // To set "bold" as `true`
 * setMark(editor, 'bold', true)
 */
export const setMark = async (
  editor: ReactEditor,
  mark: string,
  value: boolean
): Promise<void> =>
  Editor.addMark(
    /**
     * Shouldn't need to cast here, remove it and no linting type errors show
     * up. However, running the CI checks otherwise result in a type error:
     *
     * `TS2345: Argument of type 'ReactEditor' is not assignable to parameter
     * of type 'BaseEditor & ReactEditor & HistoryEditor'`
     *
     * Possibly just resolution shenanigans..
     */
    editor as BaseEditor & ReactEditor & HistoryEditor,
    mark,
    value
  );
