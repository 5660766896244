import type { CommandConfig } from "../../../../types";

import { getPlatform } from "@carescribe/utilities/src/browser";
import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { updateHistory } from "../../../../sagas/commands/history/updateHistory";
import { pressKeys } from "../../../../sagas/commands/insertion/pressKeys/pressKeys";
import { createCommandDefinition } from "../../utils/createCommandDefinition";

export const redo: CommandConfig = createCommandDefinition({
  id: "redo",
  matchers: messages.matchers,
  deadline: toSeconds(0),
  messages,
  performCommand: {
    talktype: updateHistory("redo"),
    app: pressKeys([
      {
        key: "z",
        ctrlKey: getPlatform() === "win",
        metaKey: getPlatform() === "mac",
        shiftKey: true,
      },
    ]),
  },
  input: messages.listing,
  track: { name: "Edits Reverted", data: { historyInteraction: "redo" } },
});
