import type { MoveSelectionInstruction } from "@carescribe/types";
import type { ParseIntOptions } from "@carescribe/utilities/src/parseInt";

import { matchLongestMap } from "@carescribe/utilities/src/matchLongestMap";
import { matchLongestString } from "@carescribe/utilities/src/matchLongestString";
import { parseInt } from "@carescribe/utilities/src/parseInt";

/**
 * Parses a text navigation instruction from a command string.
 *
 * @param parameters - The command parameters to parse.
 * @returns The parsed instruction or `null` if no valid instruction detected.
 *
 * @example
 * parseMoveSelectionInstruction("start of the line");
 * // { unit: "line", direction: "start", distance: null }
 *
 * @example
 * parseMoveSelectionInstruction("forward one word");
 * // { unit: "word", direction: "forward", distance: 1 }
 *
 * @example
 * parseMoveSelectionInstruction("back two paragraphs");
 * // { unit: "paragraph", direction: "backward", distance: 2 }
 *
 * @example
 * parseMoveSelectionInstruction("end of document");
 * // { unit: "document", direction: "end", distance: null }
 */
export const parseNavigationParameters = (
  parameters: string
): MoveSelectionInstruction | null => {
  if (!parameters) {
    return null;
  }

  const lowercaseParameters = parameters.toLowerCase();
  const units = new Map<string, MoveSelectionInstruction["unit"]>([
    ["character", "character"],
    ["line", "line"],
    ["word", "word"],
    ["paragraph", "paragraph"],
    ["document", "document"],
    // Homophones - similar sounding
    ["award", "word"],
  ]);
  const unit = matchLongestMap(lowercaseParameters, units);
  if (!unit) {
    return null;
  }

  switch (unit) {
    case "character":
    case "word":
    case "paragraph": {
      const directions: ["forward", "back"] = ["forward", "back"];
      const direction = matchLongestString(lowercaseParameters, directions);
      const options: ParseIntOptions = { additional: [["to", 2]] };
      const distance = parseInt(lowercaseParameters, options) ?? 1;

      return direction ? { unit, direction, distance } : null;
    }

    case "line":
    case "document": {
      const directions: ["start", "end"] = ["start", "end"];
      const direction = matchLongestString(lowercaseParameters, directions);
      return direction ? { unit, direction, distance: null } : null;
    }
  }
};
