import type { CommandConfig } from "../../../../types";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { move } from "../../../../sagas/commands/cursor/move";
import { pressKeys } from "../../../../sagas/commands/insertion/pressKeys/pressKeys";
import { createCommandDefinition } from "../../utils/createCommandDefinition";

export const arrowUp: CommandConfig = createCommandDefinition({
  id: "arrowUp",
  matchers: messages.matchers,
  deadline: toSeconds(0),
  messages,
  performCommand: {
    talktype: move("up"),
    app: pressKeys([{ key: "Up" }]),
  },
  input: messages.listing,
  track: { name: "Key Pressed", data: { key: "Arrow Up" } },
});
