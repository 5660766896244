import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { manageAddCustomWord } from "./manageAddCustomWord";
import { manageDeleteCustomWord } from "./manageDeleteCustomWord";
import { manageEditCustomWord } from "./manageEditCustomWord";
import { trackCustomWords } from "./trackCustomWords";

export const setUpCustomWords = function* (): SagaIterator<void> {
  yield all(
    [
      manageAddCustomWord,
      manageDeleteCustomWord,
      manageEditCustomWord,
      trackCustomWords,
    ].map(retry)
  );
};
