const nativeParseInt = window.parseInt;

import { matchLongestMap } from "./matchLongestMap";

const numberWordsMap = new Map<string, number>([
  ["zero", 0],
  ["one", 1],
  ["two", 2],
  ["three", 3],
  ["four", 4],
  ["five", 5],
  ["six", 6],
  ["seven", 7],
  ["eight", 8],
  ["nine", 9],
  ["ten", 10],
  ["eleven", 11],
  ["twelve", 12],
  ["thirteen", 13],
  ["fourteen", 14],
  ["fifteen", 15],
  ["sixteen", 16],
  ["seventeen", 17],
  ["eighteen", 18],
  ["nineteen", 19],
  ["twenty", 20],
  ["thirty", 30],
  ["forty", 40],
  ["fifty", 50],
  ["sixty", 60],
  ["seventy", 70],
  ["eighty", 80],
  ["ninety", 90],
  ["hundred", 100],
  ["thousand", 1000],
]);

export type ParseIntOptions = {
  additional?: [string, number][];
};

/**
 * Converts a string to an integer.
 * Like the native `parseInt` function, but more hardcore.
 *
 * It is able to convert numbers written in words (e.g. "one hundred")
 * to numbers.
 * By default can recognise numbers lower than one million, with the ability of
 * additional definitions to be passed in.
 *
 * @param text - A string to convert into a number.
 * @param options.additional - Additional word-number pairs to include in
 * the parsing. Useful for specifying homophones (e.g. similar sounding words).
 *
 * @returns The parsed integer, or null if parsing fails to find any numbers.
 *
 * @example
 * parseInt("5"); // 5
 * parseInt("one hundred twenty three"); // 123
 * parseInt("two thousand and five"); // 2005
 * parseInt("forty to", { additional: [["to", 2]] }); // 42
 * parseInt("lorem ipsum"); // null
 */
export const parseInt = (
  text: string,
  options: ParseIntOptions
): number | null => {
  const definitions = new Map(numberWordsMap);

  options.additional?.forEach(([word, number]) =>
    definitions.set(word, number)
  );

  const words = text.split(" ");
  let total = 0;
  let currentNumber = 0;
  let hasMatchedNumber = false;

  words.forEach((word) => {
    const parsedNumber = nativeParseInt(word);
    if (!isNaN(parsedNumber)) {
      currentNumber += parsedNumber;
      hasMatchedNumber = true;
      return;
    }

    const matchedNumber = matchLongestMap(word, definitions);
    if (matchedNumber === null) {
      return;
    }

    hasMatchedNumber = true;

    switch (true) {
      case matchedNumber >= 1000:
        total += (currentNumber || 1) * matchedNumber;
        currentNumber = 0;
        break;
      case matchedNumber >= 100:
        if (currentNumber === 0) {
          currentNumber = matchedNumber;
        } else {
          currentNumber *= matchedNumber;
        }
        break;
      default:
        currentNumber += matchedNumber;
    }
  });

  total += currentNumber;

  return hasMatchedNumber ? total : null;
};
