import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, take, takeEvery } from "redux-saga/effects";

import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";
import { preventDefault } from "@carescribe/utilities/src/sagas";

import { InteractionMethod, requestTrackEvent } from "@talktype/analytics";
import { editorToggledMark } from "@talktype/editor/src/sagas/actions";

import { globalKeyDown } from "./actions";
import { supportedHotkeys } from "../config/supportedHotkeys";
import { matchHotkey } from "../utils/matchHotkey";

/**
 * Set up support for hotkeys.
 *
 * Whenever a key is pressed, check if it matches a supported key shortcut
 * and dispatches the appropriate shortcut action as a result.
 */
export const manageHotkeys = function* (): SagaIterator<void> {
  yield takeEvery(
    globalKeyDown,
    function* ({ payload: event }): SagaIterator<void> {
      const isHistoryShortcut =
        ["z"].includes(event.key) && (event.ctrlKey || event.metaKey);

      const originIsEditor =
        event.target instanceof HTMLElement &&
        event.target.matches(
          `.${createSelectorClassName("editor", "interactive")}`
        );

      if (isHistoryShortcut && originIsEditor) {
        // The editor handles its own history shortcuts, so if we don't return
        // early here we get duplicated behaviour
        return;
      }

      const shortcut: SagaReturnType<typeof matchHotkey> = yield call(
        matchHotkey,
        event,
        supportedHotkeys
      );

      if (!shortcut) {
        return;
      }

      /**
       * Whenever a keyboard shortcut match is detected, we call prevent default
       * on the event.
       *
       * Why?
       *
       * Otherwise, additional unintended actions may fire alongside the
       * shortcut's intended behaviour.
       *
       * Examples:
       *
       * Undo/Redo Shortcuts
       * Browser's default behaviour must be undone as this can interfere
       * with our own undo/redo functionality by causing the contents of the
       * editor to be changed without Slate's history being kept in the loop
       *
       * Quick Nav Shortcut
       * These generate characters that we don't want to be inserted into the
       * editor, if it is focused
       *
       * Toggle Dictation Shortcut
       * This command would otherwise trigger the current page to be added to
       * the reading list in Safari, along with a distracting visual indicator
       */
      yield call(preventDefault, event);

      yield put(shortcut.action);

      switch (shortcut.type) {
        case "inline-style": {
          // Find out whether the style was toggled on or off.
          const {
            payload: toggledOn,
          }: SagaReturnType<typeof editorToggledMark> = yield take(
            editorToggledMark
          );

          yield put(
            requestTrackEvent({
              name: "Style Toggled",
              data: {
                interactionMethod: InteractionMethod.hotkey,
                formattingStyleApplied: shortcut.payload,
                newStyleStatus: toggledOn ? "on" : "off",
              },
            })
          );
          break;
        }

        case "history":
          yield put(
            requestTrackEvent({
              name: "Edits Reverted",
              data: {
                historyInteraction: shortcut.payload,
                interactionMethod: InteractionMethod.hotkey,
              },
            })
          );
      }
    }
  );
};
