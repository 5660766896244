import { isSafari } from "./browser";

/**
 * Returns the offsets at the edge of each line where text wrapping
 * occurs for a given node container. This function is useful for
 * determining the positions within a text node where lines break.
 *
 * @param container - The container node to measure the offsets for.
 * @param direction - The target direction of the offsets.
 * "start" of line offsets are on the left side of the container, whereas
 * "end" ones are on the right side.
 *
 * Methodology:
 * 1. Iterate through each character in the container.
 * 2. Check if the current character is at the start of a new line.
 * 3. If it is, add the offset to the breakpoints array.
 *
 * @example
 *
 * // Given the following text:
 * // Lorem ipsum d
 * // olor sit amet
 * // consectetur
 * //
 * // The offsets gathered for each direction would be:
 * //
 * // Direction: "start"
 * // Offsets: [0, 14, 28]
 * // Visual:  XLorem ipsum d
 * //          Xolor sit amet
 * //          Xconsectetur
 * //
 * // Direction: "end"
 * // Offsets: [13, 27, 39]
 * // Visual:  Lorem ipsum dX
 * //          olor sit ametX
 * //          consecteturX
 */
export const getEdgeOfLineOffsets = (
  container: Node,
  direction: "start" | "end"
): number[] => {
  if (container.nodeType !== Node.TEXT_NODE) {
    throw new Error("Container must be a text node");
  }

  const limit = container.textContent?.length ?? 0;

  const breakpoints: number[] = [0];

  /**
   * Safari handles text offsets slightly differently compared to other
   * browsers. This discrepancy is not fully understood yet, but it
   * necessitates an adjustment when determining the offsets.
   */
  const modifier = isSafari() ? -1 : 0;

  const testRange = document.createRange();
  const prevRange = document.createRange();

  for (let index = 1; index < limit - 1; index++) {
    testRange.setStart(container, index);
    testRange.setEnd(container, index + 1);
    const testRect = testRange.getBoundingClientRect();

    prevRange.setStart(container, index - 1);
    prevRange.setEnd(container, index);
    const prevRect = prevRange.getBoundingClientRect();

    if (testRect.top !== prevRect.top) {
      breakpoints.push(index - 1 + modifier);
      breakpoints.push(index + modifier);
    }
  }

  breakpoints.push(limit);

  return breakpoints.filter((_, index) => {
    const isEven = index % 2 === 0;
    return direction === "start" ? isEven : !isEven;
  });
};
