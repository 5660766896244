import type { MoveSelectionInstruction } from "@carescribe/types";
import type { BaseEditor } from "slate";
import type { HistoryEditor } from "slate-history";
import type { ReactEditor } from "slate-react";

import { Editor, Transforms } from "slate";

/**
 * Moves the selection cursor to the start or end of the document.
 *
 * @param editor - The editor instance
 * @param instruction - The instruction for movement
 *   - direction: "start" or "end"
 *
 * @example
 *
 * Instruction: { unit: "document", direction: "end", distance: null }
 * Description: Moves the cursor to the end of the document
 *
 * X marks the cursor position.
 *
 * Before:
 * XLorem ipsum dolor sit amet, consectetur adipiscing elit.
 * Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
 * Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
 * Nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.
 *
 * After:
 * Lorem ipsum dolor sit amet, consectetur adipiscing elit.
 * Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
 * Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
 * Nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in.X
 */
export const handleDocument = ({
  editor,
  instruction: { direction },
}: {
  editor: BaseEditor & ReactEditor & HistoryEditor;
  instruction: MoveSelectionInstruction & { unit: "document" };
}): void => {
  const getNodeAtDocumentEdge =
    direction === "end" ? Editor.last : Editor.first;
  const getPointAtNodeEdge = direction === "end" ? Editor.end : Editor.start;

  const [, newNode] = getNodeAtDocumentEdge(editor, []);
  const newSelection = getPointAtNodeEdge(editor, newNode);

  Transforms.select(editor, newSelection);
};
