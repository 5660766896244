import type { DeviceInfo } from "../types";
import type { Seconds } from "@carescribe/types/src/Units";

import { createLogger } from "@carescribe/utilities/src/log";
import { secondsToMilliseconds } from "@carescribe/utilities/src/timing";

import config from "./config.json";

export const { log, logError } = createLogger("Audio");

export const getMicrophonePermissionState = (): Promise<PermissionState> =>
  navigator.permissions
    .query({ name: "microphone" as PermissionName })
    .then((result) => result.state);

export const getUserMedia = async (
  deviceId: string | null
): Promise<
  { stream: MediaStream; error?: never } | { stream?: never; error: Error }
> => {
  const audioConstraints = deviceId === null ? true : { deviceId };

  try {
    return {
      stream: await navigator.mediaDevices.getUserMedia({
        audio: audioConstraints,
      }),
    };
  } catch (error) {
    return {
      error:
        error instanceof Error
          ? error
          : new Error(`unknown error: ${JSON.stringify(error)}`),
    };
  }
};

export const getUserDevices = (): Promise<MediaDeviceInfo[]> =>
  navigator.mediaDevices.enumerateDevices();

type CreateMediaRecorderConfig = {
  stream: MediaStream;
  listener: (blobEvent: BlobEvent) => void;
  frequency: Seconds;
};

export const createMediaRecorder = ({
  stream,
  listener,
  frequency,
}: CreateMediaRecorderConfig): MediaRecorder => {
  const recorder = new MediaRecorder(stream);

  recorder.addEventListener("dataavailable", listener);

  recorder.start(secondsToMilliseconds(frequency));

  return recorder;
};

export const stopStream = (stream: MediaStream): void => {
  stream.getTracks().forEach((track) => track.stop());
};

export const mediaStreamHasAudio = (mediaStream: MediaStream): boolean =>
  mediaStream.getAudioTracks().length > 0;

/*
    filterDevices:
    This util filters out any audio drivers.
    Most browsers: audio drivers have the "Virtual" in the label
    Safari: no Virtual label, so we need to filter any known drivers out by name
  */

export const filterDevices = (
  mediaDevices: MediaDeviceInfo[]
): DeviceInfo[] => {
  return mediaDevices
    .filter(
      (device) =>
        device.kind === "audioinput" &&
        !device.label.includes("(Virtual)") &&
        !config.knownAudioDrivers.includes(device.label)
    )
    .map(({ deviceId, label }: MediaDeviceInfo) => ({
      deviceId,
      label,
    }));
};
