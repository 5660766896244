import type { CommandConfig } from "../../../../types";

import { getPlatform } from "@carescribe/utilities/src/browser";
import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { setInlineStyle } from "../../../../sagas/commands/formatting/setInlineStyle";
import { pressKeys } from "../../../../sagas/commands/insertion/pressKeys/pressKeys";
import { createCommandDefinition } from "../../utils/createCommandDefinition";

export const underlineOff: CommandConfig = createCommandDefinition({
  id: "underlineOff",
  matchers: messages.matchers,
  messages,
  input: messages.listing,
  deadline: toSeconds(0.5),
  performCommand: {
    talktype: setInlineStyle(["underline", false]),
    app: pressKeys([
      {
        key: "u",
        ctrlKey: getPlatform() === "win",
        metaKey: getPlatform() === "mac",
      },
    ]),
  },
  track: {
    name: "Style Toggled",
    data: { formattingStyleApplied: "underline", newStyleStatus: "off" },
  },
});
