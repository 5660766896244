import type { SagaIterator, Task } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { fork, put, take, takeEvery } from "redux-saga/effects";

import { timeoutCommand } from "./timeoutCommand";
import { taskRef } from "./trackTask";
import { defaultCommandDeadline } from "../../config";
import {
  requestTrackTask,
  trackedTask,
  requestStartTask,
  startedTask,
  requestTrackDeadline,
  trackedDeadline,
} from "../actions";

/**
 * Start Task
 *
 * Starts a task that will run until the default deadline, or the command
 * specific deadline, if present.
 */
export const startTask = function* (): SagaIterator<void> {
  const { payload: task }: SagaReturnType<typeof taskRef> = yield take(taskRef);

  yield takeEvery(requestStartTask, function* () {
    const command = task.current.prompt?.command ?? null;

    const deadline = command ? command.deadline : defaultCommandDeadline;

    yield put(requestTrackDeadline(deadline));
    yield take(trackedDeadline);

    const newTask: Task = yield fork(timeoutCommand, task);

    yield put(requestTrackTask(newTask));
    yield take(trackedTask);

    yield put(startedTask());
  });
};
