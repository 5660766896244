import type { MoveSelectionInstruction } from "@carescribe/types";
import type { KeyPress } from "@talktype/types/src/Ipc/KeyPress";

export const handleCharacter = async ({
  instruction: { direction, distance },
  pressKeys,
}: {
  instruction: MoveSelectionInstruction & { unit: "character" };
  pressKeys: (keypresses: KeyPress[]) => Promise<boolean>;
}): Promise<boolean> => {
  const keypresses: KeyPress[] = [];
  const key = direction === "forward" ? "Right" : "Left";

  for (let index = 0; index < distance; index++) {
    keypresses.push({ key });
  }

  return pressKeys(keypresses);
};
