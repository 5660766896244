import type { CommandConfig } from "../../../../types";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { messages } from "./messages";
import { pressDelete as performDelete } from "../../../../sagas/commands/deletion";
import { pressKeys } from "../../../../sagas/commands/insertion/pressKeys/pressKeys";
import { createCommandDefinition } from "../../utils/createCommandDefinition";

export const pressDelete: CommandConfig = createCommandDefinition({
  id: "pressDelete",
  matchers: messages.matchers,
  deadline: toSeconds(0),
  messages,
  performCommand: {
    talktype: performDelete,
    app: pressKeys([{ key: "Backspace" }]),
  },
  input: messages.listing,
  track: { name: "Key Pressed", data: { key: "Delete" } },
});
