import type { MoveSelectionInstruction } from "@carescribe/types";
import type { BaseEditor } from "slate";
import type { HistoryEditor } from "slate-history";
import type { ReactEditor } from "slate-react";

import { Editor, Transforms, Point } from "slate";

/**
 * Moves the selection cursor forward or back by a specified number of
 * paragraphs.
 *
 * @param editor - The editor instance
 * @param instruction - The instruction for movement
 *   - direction: "forward" or "back"
 *   - distance: number of paragraphs to move
 *
 * The paragraph unit as a concept is somewhat ambiguous. For instance, what
 * does it mean to move forward a paragraph? Each operating system provides
 * a different answer:
 *
 * - Windows: Moves the cursor down a paragraph in a vertical fashion
 * - MacOS: Moves the cursor to the end of the current paragraph
 *
 * This handler aims to adopt the MacOS approach.
 *
 * @example
 *
 * // Move forward one paragraph
 * Instruction: { unit: "paragraph", direction: "forward", distance: 1 }
 * Description: Moves the cursor 1 paragraph forward
 *
 * Before:
 * XLorem ipsum dolor sit amet, consectetur adipiscing elit.
 * Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
 * Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
 *
 * After:
 * Lorem ipsum dolor sit amet, consectetur adipiscing elit.
 * Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
 * Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.X
 *
 * @example
 *
 * // Move back two paragraphs
 * Instruction: { unit: "paragraph", direction: "back", distance: 2 }
 * Description: Moves the cursor 2 paragraphs back
 *
 * Before:
 * Lorem ipsum dolor sit amet, consectetur adipiscing elit.
 * Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
 *
 * Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
 * Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.
 * Dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non.X
 *
 * After:
 *
 * XLorem ipsum dolor sit amet, consectetur adipiscing elit.
 * Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
 *
 * Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
 * Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.
 * Dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non.
 */
export const handleParagraph = ({
  editor,
  instruction: { direction, distance },
}: {
  editor: BaseEditor & ReactEditor & HistoryEditor;
  instruction: MoveSelectionInstruction & { unit: "paragraph" };
}): void => {
  const { selection } = editor;
  if (!selection) {
    return;
  }

  const getPointAtEdge = direction === "forward" ? Editor.end : Editor.start;
  const getNextNode = direction === "forward" ? Editor.next : Editor.previous;
  let newSelection =
    direction === "forward" ? selection.focus : selection.anchor;
  let remainingDistance = distance;

  while (remainingDistance > 0) {
    const pointAtEdgeOfParagraph = getPointAtEdge(editor, newSelection.path);
    const isAtParagraphEdge = Point.equals(
      newSelection,
      pointAtEdgeOfParagraph
    );

    if (!isAtParagraphEdge) {
      newSelection = pointAtEdgeOfParagraph;
      remainingDistance--;
      continue;
    }

    const nextNode = getNextNode(editor, { at: newSelection });
    if (!nextNode) {
      break;
    }
    const [, path] = nextNode;
    newSelection = getPointAtEdge(editor, path);
    remainingDistance--;
  }

  Transforms.select(editor, newSelection);
};
